"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const likeHelpers_1 = require("./helpers/likeHelpers");
class Like {
    constructor() {
        this.likeIconSelector = '.like-icon';
        this.handleLike();
        this.observe();
    }
    handleLike() {
        const likeButtons = [...document.querySelectorAll('[data-like-icon]')];
        this.setLiked();
        likeButtons && this.setListeners(likeButtons);
    }
    setListeners(likeButtons) {
        likeButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                var _a, _b;
                e.preventDefault();
                e.stopPropagation();
                const postId = (_a = button.getAttribute('data-post-id')) !== null && _a !== void 0 ? _a : '';
                const postType = (_b = button.getAttribute('data-post-type')) !== null && _b !== void 0 ? _b : '';
                this.setLocalStorage(postId, postType);
                window.dispatchEvent(this.likedPostsUpdatedEvent());
            });
            button.setAttribute('data-js-has-click', '');
        });
    }
    setLocalStorage(postId, postType) {
        let likedPostIds = (0, likeHelpers_1.getLikedPostsFromLocalStorage)();
        const index = likedPostIds.findIndex((item) => item.id === postId && item.type === postType);
        if (index === -1) {
            likedPostIds.push({ id: postId, type: postType });
        }
        else {
            likedPostIds.splice(index, 1);
        }
        localStorage.setItem('liked-posts', JSON.stringify(likedPostIds));
        this.toggleLiked(postId);
    }
    toggleLiked(postId) {
        const icons = [...document.querySelectorAll(`[data-post-id="${postId}"]`)];
        icons.forEach((icon) => {
            icon.classList.toggle('material-symbols-outlined--filled');
        });
    }
    setLiked() {
        const likedPosts = (0, likeHelpers_1.getLikedPostsFromLocalStorage)();
        likedPosts.forEach((post) => {
            const icons = [...document.querySelectorAll(`[data-post-id="${post.id}"]`)];
            icons.forEach((icon) => {
                icon.classList.add('material-symbols-outlined--filled');
            });
        });
    }
    observe() {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList' &&
                    mutation.addedNodes.length > 0) {
                    let buttons = [];
                    [...mutation.addedNodes].forEach((node) => {
                        if (node.nodeType === Node.ELEMENT_NODE &&
                            node.querySelector(this.likeIconSelector)) {
                            node.querySelectorAll(`${this.likeIconSelector}:not([data-js-has-click])`).forEach((button) => {
                                buttons.push(button);
                            });
                            this.setLiked();
                        }
                    });
                    this.setListeners(buttons);
                }
            });
        });
        observer.observe(document.body, { childList: true, subtree: true, attributes: false });
    }
    likedPostsUpdatedEvent() {
        return new CustomEvent('likedPostsLengthUpdated', {});
    }
}
exports.default = Like;
